const maskphone = (
  phoneNumber: string,
  digitsToReplase?: { digits: number[]; replaser: string },
) => {
  if (phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)

    if (match) {
      const maskedNumber = `+${match[1]} ${match[2]}-${match[3]}-${match[4]}-${match[5]}`
      const replasedNumber = digitsToReplase
        ? replaceChars(maskedNumber, digitsToReplase.digits, digitsToReplase.replaser)
        : maskedNumber
      return replasedNumber
    }

    return phoneNumber
  }
}

const replaceChars = (str: string, indexes: number[], replacementChar: string) => {
  let chars = str.split('')
  indexes.forEach((index) => {
    if (index >= 0 && index < chars.length) {
      chars[index] = replacementChar
    }
  })
  let modifiedStr = chars.join('')

  return modifiedStr
}

export default maskphone
